import { ReservationVehicleStyles } from "./ReservationVehicleStyles";
import { DeleteOutline } from "@material-ui/icons";
import { IconCar } from "icons";
import { Grid, ListItem, Paper, List, IconButton } from "@material-ui/core";
import { VariableSizeList } from "react-window";
import { AutoSizer } from "react-virtualized";
import React, { useRef, useEffect } from "react";
import { useDeleteVehicleMutation } from "../../../reservationSlice";
import usePrompt from "../../../../../hooks/usePrompt";
import NotificationProvider from "../../../../../components/NotificationProvider";
import EmptyPlaceholder from "components/EmptyPlaceholder/EmptyPlaceholder";

export const ReservationVehicleList = ({ vehiclesList, reservationId }) => {
  let [removeVehicle, { isLoading: removingLot }] = useDeleteVehicleMutation();
  const { triggerPrompt } = usePrompt();
  const classes = ReservationVehicleStyles();
  const getItemSize = () => 165;

  const onDelete = async (activeVehicleId) => {
    triggerPrompt({
      title: "Remove Vehicle from Reservation",
      content: "Are you sure you want to remove the Vehicle from Reservation?",
      onConfirm: async () => {
        let body = {
          vehicleId: activeVehicleId,
          reservationId: reservationId,
        };
        const res = await removeVehicle(body);
        if (res?.error) {
          NotificationProvider.error("Failed to remove Vehicle");
        } else {
          NotificationProvider.success("Successfully removed Vehicle");
        }
      },
    });
  };
  const Row = ({ index, style }) => {
    const activeVehicle = vehiclesList?.[index];
    return (
      <ListItem key={index}>
        <Paper className={classes.listItemWrapper}>
          <Grid item>
            <Grid container alignItems="center">
              <Grid item className={classes.itemIcon}>
                <IconCar />
              </Grid>
              <Grid item>
                <Grid container direction="column">
                  {activeVehicle.vin && (
                    <Grid item>
                      <label className={classes.itemLabel}> VIN: </label>
                      {activeVehicle.vin}
                    </Grid>
                  )}
                  {activeVehicle.assetId && (
                    <Grid item>
                      <label className={classes.itemLabel}> Asset ID: </label>
                      {activeVehicle.assetId}
                    </Grid>
                  )}
                  {activeVehicle.licensePlate && (
                    <Grid item>
                      <label className={classes.itemLabel}>
                        {" "}
                        License Plate:{" "}
                      </label>
                      {activeVehicle.licensePlate}
                    </Grid>
                  )}
                  {activeVehicle.status && (
                    <Grid item>
                      <label className={classes.itemLabel}> status: </label>
                      {activeVehicle.status}
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <IconButton className={classes.deleteIcon} 
                title={"Delete"}
                onClick={() => onDelete(activeVehicle.id)}
              >
                <DeleteOutline />
              </IconButton>
            </Grid>
          </Grid>
        </Paper>
      </ListItem>
    );
  };

  return (
    <>
      {vehiclesList?.length > 0 ? (
        <Paper className={classes.listWrapper}>
          <AutoSizer>
            {({ height, width }) => (
              <VariableSizeList
                className="List"
                height={height}
                itemCount={vehiclesList?.length || 0}
                itemSize={getItemSize}
                width={width}
                itemKey={(index) => vehiclesList[index]?.id}
              >
                {Row}
              </VariableSizeList>
            )}
          </AutoSizer>
        </Paper>
      ) : (
        <EmptyPlaceholder
          text={"Vehicle list is empty"}
          className={classes.listWrapper}
        />
      )}
    </>
  );
};
