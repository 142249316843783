import Authorize from "components/Authorize"
import { permissionProfiles } from "components/Authorize/permissionProfiles"
import ReservationForm from "./Components/ReservationForm/ReservationForm"
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import { useState } from "react";

export const AddReservation = ({ }) => {
    const [open, setOpen] = useState();


    return (
        <>
            <Authorize profile={permissionProfiles.RESERVATIONS.VIEW_TABLE}>
                <Button
                    variant="contained"
                    color='primary'
                    onClick={() => setOpen(true)}
                >
                    Create Reservation
                </Button>
            </Authorize>
            <Dialog open={open} onClose={() => setOpen()} maxWidth='xs' fullWidth>
                <DialogContent>
                    <ReservationForm />
                </DialogContent>
            </Dialog>
        </>)
}