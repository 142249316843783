import React, { useMemo } from "react";
import { useGetAllReservationsQuery } from "./reservationSlice";
import PageComponent from "components/Page/PageComponent";
import DataTable from "components/DataTable/DataTable";
import { userPreferencesComponentIds } from "components/UserPreferences/userPreferencesConsts";
import { useDispatch, useSelector } from "react-redux";
import { resetFilters, setFilters } from "./reservationsFiltersSlice";
import FilterService, { fieldTypes } from "services/filterService";
import useSortOptions from "hooks/useSortOptions";
import { Grid } from "@material-ui/core";
import { orderBy } from "lodash";
import { useGetClientsQuery } from "features/clients/clientSlice";
import { useGetLotsQuery } from "features/lots/lotSlice";
import { AddReservation } from "./NewReservation/AddReservation";
import { reservationStatusList } from "./reservationConsts";
import { arrayToObject } from "utils/object-util";
import { ReservationOverviewAction } from "./NewReservation/ReservationOverviewAction";
import { ReservationDocumentsAction } from "./NewReservation/ReservationDocumentsAction";
import { ReservationVehicleAction } from "./NewReservation/ReservationVehicleAction";

const ReservationPage = ({ }) => {

    let { data: clients, error: clientError, isLoading: isLoadingClients } = useGetClientsQuery(null);
    let { data: lots, error: lotError, isLoading: isLoadingLots } = useGetLotsQuery();
    let { data: reservations, error: reservationsError, isLoading: isLoadingReservations } = useGetAllReservationsQuery();
    reservations = reservations || [];
    const accounts = clients?.flatMap(c => c.clientAccounts);
    const { searchVal, filters } = useSelector(state => state.reservationsFilters);
    const [sortOptions, sortData] = useSortOptions();

    const reservationsLookup = useMemo(() => {
        return arrayToObject(reservations);
    }, [reservations]);

    const dispatch = useDispatch();

    const filterConfig = [
        { type: fieldTypes.TEXT, title: 'Client', key: 'clientId', values: clients },
        { type: fieldTypes.TEXT, title: 'Sub Client', key: 'clientAccountId', values: accounts },
        { type: fieldTypes.TEXT, title: 'Status', key: 'clientAccountId', values: reservationStatusList },
        { type: fieldTypes.LOT, title: 'Pickup Location', key: 'pickupLocationId', values: lots },
        { type: fieldTypes.LOT, title: 'Dropoff Location', key: 'deliveryLocationId', values: lots }
    ]

    const onDropFilter = (value, prop) => {
        dispatch(setFilters({ ...filters, [prop]: { value } }));
    };

    const onResetFilters = () => {
        dispatch(resetFilters());
    };

    const onSearch = () => {
    };

    const columns = [
        { name: "Reservation #", key: "id" },
        { name: "Client", key: "clientId", values: clients },
        { name: "Sub Client", key: "clientAccountId", values: accounts },
        { name: "Status", key: "status", values: reservationStatusList },
        { name: "Pickup Location", key: "pickupLocationId", values: lots },
        { name: "Dropoff Location", key: "deliveryLocationId", values: lots },
        { name: "Pickup Date", key: "pickupDate", component: (row) => row.formattedPickupDate },
        { name: "Pickup Time", key: "pickupTime" },
        { name: "Dropoff Date", key: "deliveryDate", component: (row) => row.formattedDeliveryDate },
        { name: "Dropoff Time", key: "deliveryTime" },
    ]

    const filteredReservations = FilterService.filter(filters, searchVal, reservations, filterConfig);

    const rowActions = (rowId) => {
        const row = reservationsLookup?.[rowId];

        return [
            {
                component: <ReservationOverviewAction reservation={row} />
            },
            {
                component: <ReservationDocumentsAction reservation={row} />
            },
            {
                component: <ReservationVehicleAction reservation={row} />
            }
        ]

    };

    return (
        <PageComponent>

            <DataTable
                showActionsInMenu
                noItemsMessage={isLoadingReservations ? 'Loading...' : 'No reservations found'}
                tableId={userPreferencesComponentIds.RESERVATIONS_TABLE}
                allowColumnCustomization={true}
                withFilterPanel={true}
                dropDownsConfig={filterConfig}
                filters={filters}
                onDropFilter={onDropFilter}
                onSearch={onSearch}
                searchVal={searchVal}
                onResetFilters={onResetFilters}
                columns={columns}
                rows={orderBy(
                    filteredReservations,
                    sortOptions.columnToSort,
                    sortOptions.sortDirection
                )}
                rowIdentifier='id'
                onSort={sortData}
                sortDirection={sortOptions.sortDirection}
                columnToSort={sortOptions.columnToSort}
                title={'Reservations'}
                actions={rowActions}
                actionButtons={
                    <Grid container direction='row' spacing={1}>
                        <Grid item>
                            <AddReservation />
                        </Grid >
                    </Grid>
                }
            />
        </PageComponent>
    )
};

export default ReservationPage;
