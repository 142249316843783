import { Button, Grid } from "@material-ui/core";
import LoadingSpinner from "components/LoadingSpinner";
import { useGetVehicleByReservationIdQuery } from "../../../reservationSlice";
import { IconCar } from "icons";
import { ReservationVehicleStyles } from "./ReservationVehicleStyles";
import { useState } from "react";
import AddIcon from "@material-ui/icons/Add";
import { ReservationVehicleList } from "./ReservationVehicleList";
import { AddVehicle } from "./AddVehicle";

const ReservationVehicle = ({ reservation }) => {
  const classes = ReservationVehicleStyles();
  const [add, setAdd] = useState(false);
  let {
    data: vehicles,
    error: vehiclesError,
    isLoading: isLoadingvehicles,
  } = useGetVehicleByReservationIdQuery(reservation?.id);

  return (
    <Grid container direction="column" spacing={2}>
      <LoadingSpinner loading={isLoadingvehicles} />
      <Grid item xs={12}>
        <Grid container direction="row" alignItems="center">
          <Grid item container display="felx" alignItems="center" xs={10}>
            <Grid item className={classes.title}>
              Reservation Vehicles
            </Grid>
          </Grid>
          <Grid item xs={2}>
            <Button
              id="basic-button"
              onClick={() => setAdd(true)}
              startIcon={<AddIcon />}
            >
              {"Add"}
            </Button>
          </Grid>
        </Grid>
      </Grid>
      {add ? (
        <AddVehicle
          setAdd={setAdd}
          client={reservation.clientId}
          reservationId={reservation?.id}
        ></AddVehicle>
      ) : (
        <></>
      )}
      
        <ReservationVehicleList
          vehiclesList={vehicles}
          reservationId={reservation?.id}
        ></ReservationVehicleList>
     
    </Grid>
  );
};

export default ReservationVehicle;
