import { Button, Grid } from "@material-ui/core";
import LoadingSpinner from "components/LoadingSpinner";
import NotificationProvider from "components/NotificationProvider";
import { useGetClientsQuery } from "features/clients/clientSlice";
import { IconReservations } from "icons";
import _ from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { useAddReservationMutation, useGetAvailableVehiclesForReservationMutation, useUpdateReservationMutation } from "../../../reservationSlice";
import ReservationAssetTypes from "../ReservationAssetTypes/ReservationAssetTypes";
import ReservationDetails from "../ReservationDetails";
import { ReservationFormStyles } from "./ReservationFormStyles";

const ReservationForm = ({ reservation }) => {
    const classes = ReservationFormStyles();

    const [driverDetails, setDriverDetails] = useState({})
    const [reservationDetails, setReservationDetails] = useState({})
    const [reservationOrder, setReservationOrder] = useState([])
    const [availableVehicleTypesList, setAvailableVehicleTypesList] = useState([])
    const [errors, setErrors] = useState({});

    const canSearch = reservationDetails?.pickupLocationId && reservationDetails?.pickupDate && reservationDetails?.pickupTime && reservationDetails?.deliveryLocationId && reservationDetails?.deliveryDate && reservationDetails?.deliveryTime;

    const [addReservation, { isLoading: isAdding }] = useAddReservationMutation();
    const [updateReservation, { isLoading: isSaving }] = useUpdateReservationMutation();

    let [getDriverUsers, { data: availableVehicleTypes, error: availableVehicleTypesError, isLoading: isLoadingAvailableVehicleTypes }] =
        useGetAvailableVehiclesForReservationMutation(
            {
                pickupLocationId: reservationDetails?.pickupLocationId,
                pickupDate: reservationDetails?.pickupDate,
                pickupTime: moment(new Date(reservationDetails?.pickupTime)).format('HH:mm'),
                deliveryLocationId: reservationDetails?.deliveryLocationId,
                deliveryDate: reservationDetails?.deliveryDate,
                deliveryTime: moment(new Date(reservationDetails?.deliveryTime)).format('HH:mm'),
            },
            { skip: !canSearch }
        );

    const resetForm = () => {
        setDriverDetails({});
        setReservationDetails({});
        setReservationOrder([]);
        setAvailableVehicleTypesList([]);
        setErrors({});
    }

    const search = async (details) => {
        const res = await getDriverUsers(
            {
                pickupLocationId: details?.pickupLocationId,
                pickupDate: details?.pickupDate,
                pickupTime: moment(new Date(details?.pickupTime)).format('HH:mm'),
                deliveryLocationId: details?.deliveryLocationId,
                deliveryDate: details?.deliveryDate,
                deliveryTime: moment(new Date(details?.deliveryTime)).format('HH:mm'),
            }
        )
        setAvailableVehicleTypesList(res.data);
    };

    const save = async () => {
        if(!validate()) {
            return;
        }

        let body = {
            ...driverDetails,
            ...reservationDetails,
            reservationDetails: reservationOrder,
            //pickupTime: moment(new Date(reservationDetails?.pickupTime)).format('HH:mm'),
            //deliveryTime: moment(new Date(reservationDetails?.deliveryTime)).format('HH:mm'),
        }
        let result;
        if (body.id) {
            result = await updateReservation(body);
        } else {
            result = await addReservation(body);
        }

        if (result?.error) {
            NotificationProvider.error('Failed to save the reservation');
        } else {
            NotificationProvider.success('Successfully saved the reservation');
            resetForm();
        }
    }

    useEffect(() => {
        if (reservation) {
            setReservationDetails({ ...reservation });
            setReservationOrder([...(reservation?.reservationDetails ?? [])]);
        }
    }, [reservation])

    const validate = () => {
        const msgs = {};
        let valid = true;

        if (!reservationDetails?.clientAccountId) {valid = false; msgs['clientAccountId'] = "Client is requeired"};
        if (!reservationDetails?.pickupDate) {valid = false; msgs['pickupDate'] = "Pickup date is required"};
        if (!reservationDetails?.pickupTime) {valid = false; msgs['pickupTime'] = "Pickup time is required"};
        if (!reservationDetails?.deliveryDate) {valid = false; msgs['deliveryDate'] = "Dropoff date is required"};
        if (!reservationDetails?.deliveryTime) {valid = false; msgs['deliveryTime'] = "Dropoff time is required"};
        if (!(!!reservationOrder?.length && reservationOrder?.some(r => r.quantity > 0))) {valid = false; msgs['assetTypes'] = "At least one asset type and quantity is required"};

        setErrors(msgs);
        return valid;
    }

    const isLoading = isSaving || isAdding;

    return (
        <>
            <Grid container direction="row" spacing={2}>
                <LoadingSpinner loading={isLoading} />
                <Grid item xs={12}>
                    <Grid container direction="row" alignItems='center'>
                        <Grid item container display="felx" alignItems='center' xs={10}>
                            <Grid item className={classes.titleIcon}>
                                <IconReservations />
                            </Grid>
                            <Grid item className={classes.title}>
                                {reservation ? 'Reservation Information' : 'New Reservation'}
                            </Grid>
                        </Grid>
                        <Grid item xs={2}>
                            <Button
                                variant="contained"
                                color="primary"
                                fullWidth
                                onClick={save}
                                disabled={isLoading}>
                                {isLoading ? 'Saving' : 'Save'}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>

                {/* <DriverDetials
                    driverDetails={driverDetails}
                    setDriverDetails={setDriverDetails}
                /> */}
                <Grid item xs={12}>
                    <ReservationDetails
                        reservationDetails={reservationDetails}
                        setReservationDetails={setReservationDetails}
                        search={search}
                        isLoading={isLoadingAvailableVehicleTypes}
                        errors={errors}
                        setErrors={setErrors}
                    />
                </Grid>
                <Grid item xs={12} className={classes.assetTypeSection}>
                    <ReservationAssetTypes
                        reservationOrder={reservationOrder}
                        setReservationOrder={setReservationOrder}
                        errors={errors}
                        setErrors={setErrors}
                    />
                </Grid>
                {/* <ReservationSearchResults
                    reservationOrder={reservationOrder}
                    setReservationOrder={setReservationOrder}
                    availableVehicleTypesList={availableVehicleTypesList}
                    isLoading={isLoadingAvailableVehicleTypes}
                /> */}

            </Grid>
        </>
    )
};

export default ReservationForm;
