import { makeStyles } from "@material-ui/core";

export const ReservationVehicleStyles = makeStyles((theme) => ({
    itemLabel: {
        fontWeight: 600,
    },
    itemIcon: {
        paddingRight: '1em',
    },
    titleIcon: {
        marginRight: '0.5rem'
    },
    title: {
        fontWeight: 500,
        fontSize: '1.2rem'
    },
    listItemWrapperNew: {
        borderColor: '#90C534',
        position: 'relative',
        "&::before": {
            content: "'NEW'",
            color: 'white',
            background: '#90C534',
            position: "absolute",
            fontSize: '0.8em',
            padding: '1px 5px',
            top: '-0.2em',
            left: "1em",
            borderRadius: '4px',
            transform: "translate(0px, -50%)",
        },
    },
    btnWrapper: {
        marginTop: '1em',
    },
    sectionTitle: {
        color: theme.palette.primary.main,
        fontWeight: 600,
        marginBottom: '1em'
    },
    listItemWrapper: {
        margin: '0.5em 0',
        padding: '1em',
        border: '1px solid transparent',
        width: '100%',
        "&:hover": {
            "& $lotActions": {
                opacity: 1,
            }
        }
    },
    deleteIcon:{
        "margin-left": "auto"
    },
    rowListItem: {
        display: 'block'
    },
      listWrapper: {
         background: 'white',
         display: 'flex',
         flexDirection: 'column',
         height: '500px',
         "& ul": {
             flex: '1'
        }
     },
}))