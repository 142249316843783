import Authorize from "components/Authorize"
import { permissionProfiles } from "components/Authorize/permissionProfiles"
import ReservationVehicle from "./Components/ReservationVehicle/ReservationVehicle"
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import { useState } from "react";
import LoadingSpinner from "components/LoadingSpinner";

export const ReservationVehicleAction = ({ reservation}) => {
    const [open, setOpen] = useState();
    return (
        <>
            <Authorize profile={permissionProfiles.RESERVATIONS.VIEW_TABLE}>
                <Button
                    color='primary'
                    onClick={() => setOpen(true)}
                >
                    Assets
                </Button>
            </Authorize>
            <Dialog open={open} onClose={() => setOpen()} maxWidth='xs' fullWidth>
                <DialogContent>
                    <ReservationVehicle reservation={reservation} />
                </DialogContent>
            </Dialog>
        </>)
}