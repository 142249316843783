import { Button, Grid, Paper } from "@material-ui/core";
import React, { useState } from "react";
import { ReservationFormStyles } from "./ReservationForm/ReservationFormStyles";
import { useGetLotsQuery } from "features/lots/lotSlice";
import DropdownFilter from "components/DropdownFilter";
import { TimeField } from "components/TimeField/TimeField";
import DatePicker from "components/DatePicker";
import { IconSearch } from "icons";
import moment from "moment";
import { useGetClientsQuery } from "features/clients/clientSlice";
import _ from "lodash";
import CustomInput from "components/CustomInput";
import { Alert } from "@mui/material";

const ReservationDetails = ({
    reservationDetails,
    setReservationDetails,
    search,
    isLoading = false,
    errors, setErrors
}) => {
    const classes = ReservationFormStyles();
    let { data: lots, error: lotError, isLoading: isLoadingLots } = useGetLotsQuery();
    const [details, setDetails] = useState(reservationDetails)

    let { data: clients, error: clientError, isLoading: isLoadingClients } = useGetClientsQuery(null);
    clients = clients || [];

    const clientAccounts = _.flatten(clients.map(c => c.clientAccounts));

    //const canSearch = details?.pickupLocationId && details?.pickupDate && details?.pickupTime && details?.deliveryLocationId && details?.deliveryDate && details?.deliveryTime;

    const onChangeValue = (key, val) => {
        setReservationDetails({ ...reservationDetails, [key]: val })
        if(val) {
            setErrors({ ...errors, [key]: undefined });
        }
    }

    // const onSearch = async () => {
    //     setReservationDetails({ ...reservationDetails, ...details });
    //     await search(details);
    // }

    const isNew = !reservationDetails?.id;

    const displayError = (field) => {
        return !!errors?.[field] && <Grid xs={12} className={classes.alertWrapper}>
                    <Alert severity='error' >
                        {errors?.[field]}
                    </Alert>
                </Grid>
    }

    return (
        <>

            <div className={classes.sectionTitle}>
                Reservation Details
            </div>
            <Grid container alignItems="center" spacing={1}>
                <Grid item xs={12} className={classes.field}>
                    <div className={classes.label}>
                        Client
                    </div>
                    <CustomInput
                        id="clientAccountId"
                        value={reservationDetails?.clientAccountId}
                        elementType="dropdown"
                        onChange={val => onChangeValue("clientAccountId", +val)}
                        values={clientAccounts}
                        showEmpty
                        required
                    />
                </Grid>
                {displayError('clientAccountId')}
                <Grid item xs={12} className={classes.field}>
                    <div className={classes.label}>
                        Pickup Date
                    </div>
                    <DatePicker
                        id="pickupDate"
                        value={reservationDetails?.pickupDate ? moment(reservationDetails?.pickupDate).format("YYYY-MM-DD") : null}
                        //title="Pickup Date"
                        onSelect={(val) => onChangeValue("pickupDate", val ? val : null)}
                        required
                        minDate={moment(Date.now()).format("YYYY-MM-DD")}
                        maxDate={reservationDetails?.deliveryDate ? moment(reservationDetails?.deliveryDate).format("YYYY-MM-DD") : null}
                    />
                </Grid>
                {displayError('pickupDate')}
                <Grid item xs={12} className={classes.field}>
                    <div className={classes.label}>
                        Pickup Hour
                    </div>
                    <TimeField
                        id="pickupTime"
                        onChange={(val) => {
                            console.log(moment(new Date(val)).format('HH:mm:ss'));
                            console.log(val);
                            onChangeValue("pickupTime", moment(new Date(val)).format('HH:mm:ss'))
                        }}
                        //value={reservationDetails?.deliveryTime ?? null}
                        value={reservationDetails?.pickupTime ? new Date(new Date().setHours(...(reservationDetails?.pickupTime ?? '').split(':').map(Number))) : null}
                        required
                        //title="Dropoff Hour"
                    />
                </Grid>
                {displayError('pickupTime')}
                <Grid item xs={12} className={classes.field}>
                    <div className={classes.label}>
                        Dropoff Date
                    </div>
                    <DatePicker
                        id="deliveryDate"
                        value={reservationDetails?.deliveryDate ? moment(reservationDetails?.deliveryDate).format("YYYY-MM-DD") : null}
                        //title="Dropoff Date"
                        onSelect={(val) => onChangeValue("deliveryDate", val ? val : null)}
                        required
                        minDate={reservationDetails?.pickupDate ? moment(reservationDetails?.pickupDate).format("YYYY-MM-DD") : moment(Date.now()).format("YYYY-MM-DD")}
                    />
                </Grid>
                {displayError('deliveryDate')}
                <Grid item xs={12} className={classes.field}>
                    <div className={classes.label}>
                        Dropoff Hour
                    </div>
                    <TimeField
                        id="deliveryTime"
                        onChange={(val) => {
                            console.log(moment(new Date(val)).format('HH:mm:ss'));
                            console.log(val);
                            onChangeValue("deliveryTime", moment(new Date(val)).format('HH:mm:ss'))
                        }}
                        //value={reservationDetails?.deliveryTime ?? null}
                        value={reservationDetails?.deliveryTime ? new Date(new Date().setHours(...(reservationDetails?.deliveryTime ?? '').split(':').map(Number))) : null}
                        required
                        //title="Dropoff Hour"
                    />
                </Grid>
                {displayError('deliveryTime')}
                <Grid item xs={12} className={classes.field}>
                    <div className={classes.label}>
                        Pickup Location
                    </div>
                    <DropdownFilter
                        id="pickupLocationId"
                        value={reservationDetails?.pickupLocationId}
                        onSelect={(val) => onChangeValue("pickupLocationId", val.id)}
                        elementType="dropdown"
                        values={lots}
                        withTypeAhead
                        //title="Pickup Location"
                        shrink={reservationDetails?.pickupLocationId}
                    />
                </Grid>
                <Grid item xs={12} className={classes.field}>
                    <div className={classes.label}>
                        Dropoff Location
                    </div>
                    <DropdownFilter
                        id="deliveryLocationId"
                        value={reservationDetails?.deliveryLocationId}
                        onSelect={(val) => onChangeValue("deliveryLocationId", val.id)}
                        elementType="dropdown"
                        values={lots}
                        withTypeAhead
                        //title="Dropoff Location"
                        shrink={reservationDetails?.deliveryLocationId}
                    />
                </Grid>
                {/* <Grid item xs={1} >
                            <Button
                                color="primary"
                                variant="contained"
                                endIcon={<IconSearch fill="white" />}
                                disabled={!canSearch || isLoading}
                                onClick={onSearch}>
                                Search...
                            </Button>
                        </Grid> */}
            </Grid>
        </>
    )
};

export default ReservationDetails;
