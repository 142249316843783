import { apiTags } from 'app/store/apiTags';
import { baseQuery } from 'app/baseQuery';
import { baseApi } from 'app/baseApi';
import FormatService from 'services/formatService';

const apiPrefix = 'reservation';
export const reservationApi = baseApi.injectEndpoints({
    reducerPath: 'reservationApi',
    tagTypes: [apiTags.RESERVATION],
    baseQuery: baseQuery,
    endpoints: (builder) => ({
        getAllReservations: builder.query({
            query: () => `${apiPrefix}`,
            providesTags: [apiTags.RESERVATION],
            transformResponse: (response) => {
                return response?.map(res => ({
                    ...res,
                    formattedPickupDate: FormatService.formatDateNoConversion(res.pickupDate),
                    formattedDeliveryDate: FormatService.formatDateNoConversion(res.deliveryDate)
                }))
            }
        }),
        getAvailableVehiclesForReservation: builder.mutation({
            query: (body) => ({
                url: `${apiPrefix}/availableTypes`,
                method: "POST",
                body: body,
            })
        }),
        getUserDrivers: builder.query({
            query: () => `${apiPrefix}/userDrivers`,
        }),
        addReservation: builder.mutation({
            query: reservation => ({
                url: `${apiPrefix}`,
                method: "POST",
                body: reservation
            }),
            invalidatesTags: [apiTags.RESERVATION]
        }),
        addVehicle: builder.mutation({
            query: reservation => ({
                url: `${apiPrefix}/addVehicle`,
                method: "POST",
                body: reservation
            }),
            invalidatesTags: [apiTags.VEHICLE],
        }),
        deleteVehicle: builder.mutation({
            query: reservationVehicle => ({
                url: `${apiPrefix}/deleteVehicle`,
                method: "DELETE",
                body: reservationVehicle
            }),
            invalidatesTags: [apiTags.VEHICLE],
        }),
        updateReservation: builder.mutation({
            query: reservation => ({
                url: `${apiPrefix}`,
                method: "PUT",
                body: reservation
            }),
            invalidatesTags: [apiTags.RESERVATION]
        }),
        getVehicleByReservationId: builder.query({
            query: (reservationId) => `${apiPrefix}/GetVehicle/${reservationId}`,
            providesTags: [apiTags.VEHICLE],
        }),
       
    }),
});

export const {
    useGetAllReservationsQuery,
    useGetAvailableVehiclesForReservationMutation,
    useGetUserDriversQuery,
    useAddReservationMutation,
    useAddVehicleMutation,
    useDeleteVehicleMutation,
    useUpdateReservationMutation,
    useGetVehicleByReservationIdQuery
} = reservationApi;