import { IconArrowCircle, IconDatePicker, IconImage, IconMessage, IconRadioButton, IconSign, IconToggle } from "icons"
import QuestionValues from "./FormQuestions/components/QuestionValues"
import DateRangeRoundedIcon from '@material-ui/icons/DateRangeRounded';
import { clientConfigTypes } from "features/clients/clientConfigsSetup";
import ToggleOnOutlinedIcon from '@material-ui/icons/ToggleOnOutlined';
import { arrayToObject } from "utils/object-util";
import { vehicle_detail_extended_type, vehicleDetailExtendedTypeLabels } from "features/vehicles/VehicleDetailExtended/VehicleDetailExtendedTypes";

export const formTypes = {
    Checkin: 1,
}

export const formStatuses = {
    Draft: 1,
    Published: 2
}

export const formStatusOptions = [
    {
        id: formStatuses.Draft,
        name: 'Draft'
    },
    {
        id: formStatuses.Published,
        name: 'Published'
    }
]

export const formTypeOptions = [
    {
        id: formTypes.Checkin,
        name: 'Check in',
        configInfo: {
            configType: clientConfigTypes.Checkin,
            parameterName: 'useCustomCheckin'
        }
    }
]

export const questionTypes = {
    Freetext: 1,
    Dropdown: 2,
    MultipleChoice: 3,
    Image: 4,
    Signature: 5,
    Date: 6,
    Boolean: 7
}

export const questionTypeSetup = {
    [questionTypes.Freetext]: {
        name: 'Short answer',
        icon: <IconMessage />
    },
    [questionTypes.Dropdown]: {
        name: 'Dropdown',
        component: QuestionValues,
        icon: <IconArrowCircle />
    },
    [questionTypes.MultipleChoice]: {
        name: 'Multiple choice',
        component: QuestionValues,
        icon: <IconRadioButton />
    },
    [questionTypes.Image]: {
        name: 'Image',
        icon: <IconImage />
    },
    [questionTypes.Signature]: {
        name: 'Signature',
        icon: <IconSign />
    },
    [questionTypes.Date]: {
        name: 'Date',
        icon: <IconDatePicker />
    },
    [questionTypes.Boolean]: {
        name: 'Boolean',
        icon: <IconToggle />
    }
}

export const emptyForm = (formType) => {
    return {
        formType,
        name: '',
        sections: [
            {
                name: '',
                order: 1,
                questions: []
            }
        ]
    }
}

export const vehicleDetailTypes = {
    vehicle: 1,
    vehicleDetail: 2,
    vehicleDetailExtended: 3,

}

export const vehicleFieldsToMap = {
    vehicleType: 1,
    year: 2,
    make: 3,
    model: 4,
    color: 5,
    odometer: 6,
    fuelLevel: 7,
    DOTLastInspectionDate: 8,
    treadDepth: 9,
    breakPadDepth: 10,
    batteryLevel: 11,
    checkEngineLight: 12,
    otherDashLight: 13,
    keysHandedOver: 14,
    fobsHandedOver: 15,
    assetId: 16,
    licensePlate: 17
}

const vdFieldsToMapTo = arrayToObject(Object.values(vehicle_detail_extended_type)?.map(vde => ({
    name: vehicleDetailExtendedTypeLabels[vde],
    id: {
        type: vehicleDetailTypes.vehicleDetailExtended,
        field: vde
    },
    key: vde,
    questionType: questionTypes.Freetext
})), 'key');

export const vehicleFieldsToMapSetup = {
    // [vehicleFieldsToMap.vehicleType]: {
    //     name: 'Vehicle Type',
    //     id: {
    //         type: vehicleDetailTypes.vehicle,
    //         field: 1
    //     },
    //     questionType: questionTypes.Dropdown,
    // },
    [vehicleFieldsToMap.assetId]: {
        name: 'Asset Id',
        id: {
            type: vehicleDetailTypes.vehicle,
            field: 7
        },
        questionType: questionTypes.Freetext
    },
    [vehicleFieldsToMap.licensePlate]: {
        name: 'License Plate',
        id: {
            type: vehicleDetailTypes.vehicle,
            field: 8
        },
        questionType: questionTypes.Freetext
    },
    [vehicleFieldsToMap.year]: {
        name: 'Year',
        id: {
            type: vehicleDetailTypes.vehicle,
            field: 2
        },
        questionType: questionTypes.Freetext
    },
    [vehicleFieldsToMap.make]: {
        name: 'Make',
        id: {
            type: vehicleDetailTypes.vehicle,
            field: 3
        },
        questionType: questionTypes.Freetext
    },
    [vehicleFieldsToMap.model]: {
        name: 'Model',
        id: {
            type: vehicleDetailTypes.vehicle,
            field: 4
        },
        questionType: questionTypes.Freetext
    },
    [vehicleFieldsToMap.color]: {
        name: 'Color',
        id: {
            type: vehicleDetailTypes.vehicle,
            field: 5
        },
        questionType: questionTypes.Freetext
    },
    [vehicleFieldsToMap.odometer]: {
        name: 'Odemeter',
        id: {
            type: vehicleDetailTypes.vehicleDetail,
            field: 6
        },
        questionType: questionTypes.Freetext
    },
    ...vdFieldsToMapTo
    /* [vehicleFieldsToMap.DOTLastInspectionDate]: {
         name: 'DOT Last Inspection Date',
         id: {
             type: vehicleDetailTypes.vehicleDetailExtended,
             field: 4
         },
         questionType: questionTypes.Freetext
     },
     [vehicleFieldsToMap.treadDepth]: {
         name: 'Tread Depth',
         id: {
             type: vehicleDetailTypes.vehicleDetailExtended,
             field: 5
         },
         questionType: questionTypes.Freetext
     },
     [vehicleFieldsToMap.breakPadDepth]: {
         name: 'Break Pad Depth',
         id: {
             type: vehicleDetailTypes.vehicleDetailExtended,
             field: 5
         },
         questionType: questionTypes.Freetext
     },
     [vehicleFieldsToMap.batteryLevel]: {
         name: 'Battery Level',
         id: {
             type: vehicleDetailTypes.vehicleDetailExtended,
             field: 5
         },
         questionType: questionTypes.Freetext
     },
     [vehicleFieldsToMap.checkEngineLight]: {
         name: 'Check Engine Light',
         id: {
             type: vehicleDetailTypes.vehicleDetailExtended,
             field: 5
         },
         questionType: questionTypes.Freetext
     },
     [vehicleFieldsToMap.otherDashLight]: {
         name: 'Other Dash Light',
         id: {
             type: vehicleDetailTypes.vehicleDetailExtended,
             field: 6
         },
         questionType: questionTypes.Freetext
     },
     [vehicleFieldsToMap.keysHandedOver]: {
         name: 'Keys Handed Over',
         id: {
             type: vehicleDetailTypes.vehicleDetailExtended,
             field: 18
         },
         questionType: questionTypes.Freetext
     },
     [vehicleFieldsToMap.fobsHandedOver]: {
         name: 'Fobs Handed Over',
         id: {
             type: vehicleDetailTypes.vehicleDetailExtended,
             field: 19
         },
         questionType: questionTypes.Freetext
     },*/
}

export const vehicleFieldsToMapByQType = {
    [questionTypes.Freetext]: Object.values(vehicleFieldsToMapSetup).filter(a => a.questionType === questionTypes.Freetext),
    [questionTypes.Dropdown]: Object.values(vehicleFieldsToMapSetup).filter(a => a.questionType === questionTypes.Dropdown),
}
